import React from "react";
import { UserContext } from "../App";
import { API } from "aws-amplify";
// import { Icon } from "react-materialize";


// Function to convert Base64 to Blob
function base64ToBlob(base64String, contentType = '') {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
}

class Report extends React.Component {
  state = {
    report: []
  };
  componentDidMount() {
    this.get_report();
  }
  get_report = async () => {
    this.setState.report = "";
    let apiName = "inputIPs-API";

    let path = "";

    const query = new URLSearchParams(this.props.location.search);
    let reportType = query.get("report_type");

    console.log("Report Type: ", reportType);
    console.log(this.props.location.search);
    if(reportType === "cra"){
      path = "report/" + this.props.match.params.project_id + "?report_type=cra";
    } else if(reportType === "csp"){
      path = "report/" + this.props.match.params.project_id + "?report_type=csp";
    } else {
      path = "report/" + this.props.match.params.project_id + "?report_type=csp";
    }
    
    API.get(apiName, path).then(response => {
      console.log(response);
      //const decodedReport = atob(response);
      const b64blob = base64ToBlob(response);
      const url = window.URL.createObjectURL(b64blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", "report.docx");
      document.body.appendChild(link);
      link.click();
    });
  };
  createHtml(report){
      return {__html: ""}
  }
 
  render() {
    if (!this.props.match.params.project_id) {
        return <div >Loading....</div>;
      }
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <>
          
          {/* Well shit, it looks like my life has come to this, I've told devs not to use  this forever but here I am.
          The html is all system generated so :man-shrugging: */}
           <div id="report" dangerouslySetInnerHTML={this.createHtml(this.state.report.report)}/>
           
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Report;
